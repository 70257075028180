<template>
    <div v-if="active" class="mr-4">
        <a @click="open()" class="view-toggle radius-anchor qr">
            <!--            {{ $t('selfscanner.open') }}-->
        </a>

        <transition name="blur-background">
            <blur v-if="show">
            </blur>
        </transition>

        <transition name="modal-small">
            <div class="fixed z-50 inset-0 overflow-y-auto ccss--modal-selfscan fixed-modal-wrapper" v-show="show">
                <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                    <div class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full radius-anchor"
                         role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                        <div class="">

                            <div class="modal-close">
                                <a @click="close()" class="modal-close" aria-label="Close">
                                    <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                              d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </a>
                            </div>

                            <div class="sm:flex sm:items-start">
                                <div class="text-center py-8 px-4  sm:rounded-sm sm:px-10 pb-0 block w-full text-center">
                                    <h3 class="mt-3 text-lg leading-6 font-bold text-2xl text-gray-900" id="modal-headline">
                                        {{ $t('selfscanner.title') }}
                                    </h3>
                                </div>
                            </div>
                            <div>
                                <div class="mt-5 camera-placeholder">
                                    <div v-if="camerastatus == 1" class="flex justify-center">{{ $t('selfscanner.camerabusy') }}...</div>
                                    <button v-if="camerastatus == 3" type="button" @click="openCamera()" class="mb-10 items-center cursor-pointer inline-flex justify-center w-full rounded-sm px-4 py-2 btn btn-primary text-lg leading-6 font-medium shadow-sm focus:outline-none sm:text-sm sm:leading-5">
                                        {{ $t('selfscanner.cameraopenagain') }}
                                    </button>

                                    <barcode-reader
                                        ref="barcodereader"
                                        @decode="onDecode"
                                        @loaded="onLoaded"
                                    ></barcode-reader>

                                    <div class="bg-gray-100 p-4 rounded-sm mt-3 text-center" v-if="camerastatus == 3 && result">
                                        {{ $t('selfscanner.scanned') }}: {{result}}
                                        <div v-if="notification == 'success'">
                                            <p class="text-center font-sm text-green-500">
                                                {{ $t('selfscanner.notification.success') }} {{ initialitem.attributes.description }}
                                            </p>
                                        </div>
                                        <div v-if="notification == 'failed'">
                                            <p class="text-center font-sm text-red-500">
                                                {{ $t('selfscanner.notification.failed') }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-4 block">
                            <span class="flex w-full rounded-sm shadow-sm sm:w-auto">
                                <button type="button" @click="close()" class="btn btn-primary inline-flex w-full justify-center items-center px-6 py-3 text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                                    {{ $t('button.close') }}
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <!-- todo: move to own component -->
        <!-- modal -->
        <div v-if="show_modal" class="fixed z-50 inset-0 overflow-y-auto">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
                <div id="modal" class="w-full inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <!-- <div id="modal" class="w-full inline-block align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg" role="dialog" aria-modal="true" aria-labelledby="modal-headline"> -->
                    <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                        <button @click="closeModal()" type="button" class="bg-white rounded-sm text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <span class="sr-only">Close</span>
                            <!-- Heroicon name: x -->
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div id="modal-content" class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 overflow-auto">
                        <div class="sm:flex sm:items-start">
                            <div class="mt-3 text-center sm:mt-0 sm:text-left">
                                <h3 class="text-lg leading-6 font-bold text-2xl text-gray-900" id="modal-headline">
                                    {{ item.attributes.webshop_description }}
                                </h3>
                            </div>
                        </div>

                        <div class="max-h-96">
                            <!-- Details -->
                            <div v-if="step == 'details'">
                                <div class="mt-2">
                                    <div v-if="item.attributes.thumbnail_url" class="flex justify-center">
                                        <img class="max-h-20" :src="item.attributes.thumbnail_url">
                                    </div>
                                    <p>{{ item.attributes.webshop_description_long }}</p>
                                </div>
                            </div>

                            <!-- Variants -->
                            <div v-if="step == 'variants'">
                                <div class="mt-2 flex justify-center border rounded-smp-5" v-if="variant">
                                    <img class="max-h-20" :src="variant.attributes.thumbnail_url"/>
                                </div>
                                <div class="flex justify-center mt-4">
                                    <span class="relative z-0 inline-flex shadow-sm rounded-sm">
                                        <div v-for="(item_variant, variant_index) in item.attributes.variants" :key="variant_index">
                                            <button type="button" @click="variantSelect(item_variant)"
                                                    class="relative inline-flex items-center px-4 py-2 -ml-px border text-sm leading-5 font-medium focus:z-10 focus:outline-none"
                                                    :class="item_variant.id == (variant ? variant.id : null) ? 'btn-primary' : 'btn-secondary'">
                                                {{ item_variant.attributes.label }} {{ $n(parseFloat(item_variant.attributes.price), currency) }}
                                            </button>
                                        </div>
                                    </span>
                                </div>
                                <div class="text-xs text-red-600" v-if="errors.variants && !variant">verplicht</div>
                            </div>

                            <!-- Addons -->
                            <div v-if="step == 'addons'">
                                <div class="mb-3" v-for="(addon, addon_index) in addons" :key="addon_index">
                                    <div class="font-bold flex justify-between">
                                        <div>
                                            {{ addon.description }}
                                            <span v-if="addon.quantity_min > 0">*</span>
                                        </div>
                                        <div>
                                            <!-- <div>{{ option.description }} ({{ option.price }})</div> -->
                                            <div class="text-xs text-red-600" v-if="addon.error">
                                                {{ $t('label.select') }}
                                                <span v-if="addon.quantity_min && addon.quantity_max">minimaal {{ addon.quantity_min}} en maximaal {{ addon.quantity_max }}</span>
                                                <span v-else-if="addon.quantity_min">minimaal {{ addon.quantity_min}}</span>
                                                <span v-else-if="addon.quantity_max">maximaal {{ addon.quantity_max }}</span>
                                            </div>
                                        </div>
                                        <!-- <span class="text-xs text-red-600" v-if="hasError('addons', addon.description)">verplicht</span> -->
                                    </div>
                                    <div class="mb-3" v-for="(option, option_index) in addon.options" :key="option_index">
                                        <!-- Quantity -->
                                        <div v-if="addon.type == 'buttons'" class="flex justify-start items-center">
                                            <div class="flex rounded-sm  items-center justify-end mr-5">
                                                <div class="">
                                                    <a @click="changeAddonQuantity(option, -1, addon)" class="block rounded-sm border border-gray-200 p-1">
                                                        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z" />
                                                        </svg>
                                                    </a>
                                                </div>
                                                <div class="px-5">
                                                    {{ parseInt(option.quantity) }}
                                                </div>
                                                <div>
                                                    <a @click="changeAddonQuantity(option, 1, addon)" class="block rounded-sm border border-gray-200 p-1">
                                                        <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="flex justify-between items-center w-full">
                                                <div>
                                                    <div>{{ option.description }} ({{ option.price }})</div>
                                                    <div class="text-xs text-red-600" v-if="option.error">
                                                        <span v-if="option.quantity_min && option.quantity_max">minimaal {{ option.quantity_min}} en maximaal {{ option.quantity_max }}</span>
                                                        <span v-else-if="option.quantity_min">minimaal {{ option.quantity_min}}</span>
                                                        <span v-else-if="option.quantity_max">maximaal {{ option.quantity_max }}</span>
                                                    </div>
                                                </div>
                                                <div>{{ $n(option.quantity * option.price, currency) }}</div>
                                            </div>
                                        </div>

                                        <!-- Radio -->
                                        <div v-if="addon.type == 'radio'" class="flex justify-between items-center">
                                            <div class="flex items-center justify-start gap-5">
                                                <input @change="addonRadioSelect(addon, option)" type="radio" :id="option.id" :name="addon.id" :value="option.id" class="focus:ring-0">
                                                <label :for="option.id">{{ option.description }} ({{ $n(option.price, currency) }})</label>
                                            </div>
                                            <div v-if="option.quantity > 0">{{ $n(option.quantity * option.price, currency) }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Kitchen Groceries -->
                            <div v-if="step == 'kitchen_groceries'">
                                <div class="my-3" v-for="(kitchen_grocery, kitchen_grocery_index) in item.attributes.kitchen_groceries" :key="kitchen_grocery_index">
                                    <fieldset>
                                        <legend class="w-full font-bold flex justify-between">
                                            <span>{{ kitchen_grocery.description }} *</span>
                                            <span class="text-xs text-red-600" v-if="hasError('kitchen_groceries', kitchen_grocery.description)">verplicht</span>
                                        </legend>
                                        <ul class="space-y-1 addon" role="radiogroup" aria-labelledby="radiogroup-label">
                                            <li v-for="(option, option_index) in kitchen_grocery.options.split(';')" :key="option_index" tabindex="0" role="radio" class="group relative bg-white rounded-sm shadow-sm cursor-pointer focus:outline-none">
                                                <div @click="setKitchenGrocery(kitchen_grocery.description, option)" class="rounded-sm border px-3 py-1 sm:flex sm:justify-between"
                                                     :class="{'active': isKitchenGrocerySelected(kitchen_grocery.description, option)}"
                                                >
                                                    <div class="flex items-center">
                                                        <div class="text-sm">
                                                            <p class="font-medium text-gray-900">
                                                                {{ option }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content-footer px-4 py-3 sm:px-6 sm:flex sm:justify-between items-center">

                        <!-- Quantity -->
                        <div class="flex rounded-sm sm:mb-0 mb-3 items-center justify-center sm:justify-end" v-if="canOrder">
                            <div class="">
                                <button type="button" @click="subQuantity()" class="rounded-sm border p-1">
                                    <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z" />
                                    </svg>
                                </button>
                            </div>
                            <div class="text-main px-5">
                                {{ quantity }}
                            </div>
                            <div>
                                <button type="button" @click="addQuantity()" class="rounded-sm border p-1">
                                    <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div class="sm:flex sm:flex-row-reverse">
                            <span class="flex w-full rounded-sm shadow-sm sm:ml-3 sm:w-auto" v-if="canOrder">
                                <button v-if="step == 'details'" type="button" @click="nextStep('details')" class="inline-flex justify-center w-full rounded-sm px-4 py-2 btn btn-primary text-base leading-6 font-medium shadow-sm focus:outline-none sm:text-sm sm:leading-5">
                                    {{ $t('modal.item.button.confirm') }}
                                </button>
                                <button v-if="step == 'variants'" type="button" @click="confirmVariant()" class="inline-flex justify-center w-full rounded-sm px-4 py-2 btn btn-primary text-base leading-6 font-medium shadow-sm focus:outline-none sm:text-sm sm:leading-5">
                                    {{ $t('modal.item.button.confirm') }}
                                </button>
                                <button v-if="step == 'addons'" type="button" @click="confirmAddons()" class="inline-flex justify-center w-full rounded-sm px-4 py-2 btn btn-primary text-base leading-6 font-medium shadow-sm focus:outline-none sm:text-sm sm:leading-5">
                                    {{ $t('modal.item.button.confirm') }}
                                </button>
                                <button v-if="step == 'kitchen_groceries'" type="button" @click="confirmKitchenGroceries()" class="inline-flex justify-center w-full rounded-sm px-4 py-2 btn btn-primary text-base leading-6 font-medium shadow-sm focus:outline-none sm:text-sm sm:leading-5">
                                    {{ $t('modal.item.button.confirm') }}
                                </button>
                                <!-- <button type="button" @click="addItemToCart()" class="inline-flex justify-center w-full rounded-sm px-4 py-2 btn btn-primary text-base leading-6 font-medium shadow-sm focus:outline-none sm:text-sm sm:leading-5">
                                Toevoegen
                            </button> -->
                            </span>
                            <span class="mt-3 flex w-full rounded-sm shadow-sm sm:mt-0 sm:w-auto">
                                <button type="button" @click="closeModal()" class="inline-flex justify-center w-full rounded-sm border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                                    {{ $t('modal.item.button.close') }}
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import * as _api from '../lib/api';
import * as _state from "../lib/state";
// components
import BarcodeReader from './BarcodeReader.vue';
import Blur from "./Blur";
import ItemHelper from '../mixins/item.helper.js';
export default {
    components: {
        // QrStream,
        BarcodeReader,
        Blur
    },
    mixins: [ItemHelper, BarcodeReader],
    data () {
        return {
            show: false,
            initialitem: null,
            camerastatus: 1, // 1 = loading, 2 = stream, 3 = finished
            result: null,
            notification: '',
            canOrder: true,
        }
    },
    methods: {
        open() {
            this.openCamera()
            this.show = true
        },
        openCamera() {
            this.reset()
            this.camerastatus = 1
            this.$refs.barcodereader.openCamera()
        },
        close() {
            this.show = false
            this.reset()
            this.$refs.barcodereader.closeCamera()
        },
        reset() {
            this.result = null
            this.notification = null
        },
        onLoaded() {
            this.camerastatus = 2
        },
        async onDecode (content) {
            this.reset()
            this.result = content
            this.$refs.barcodereader.closeCamera()
            axios.defaults.headers.common['Location'] = this.location_slug
            // todo: needs testing
            const response = await _api.post('items/search/barcode', { barcode: this.result });
            this.camerastatus = 3;
            if (response === 'failed') {
                this.notification = 'failed';
                return;
            }
            this.initialitem = response.data;
            this.addItem();
        },
        itemIsAdded() {
            this.notification = 'success'
        },
    },
    computed: {
        location_slug() {
            return this.$route.params.location_slug
        },
        config () {
            return _state.get('config/getConfig');
        },
        active() {
            return this.config.settings.selfscan && this.$route.name === 'quickorder';
        },
        currency (){
            return this.config.settings.currency;
        }
    }
}
</script>

<style scoped>
.camera-placeholder {
    height: 230px;
}
</style>
