<template>
    <transition name="fade">
        <div class="menu-loading loading-full-screen" v-if="loading_animation">
            <div class="snippet" data-title=".dot-pulse">
                <div class="stage">
                    <div class="dot-pulse"></div>
                </div>
            </div>
        </div>
    </transition>

    <div v-show="!loading">
        <transition name="slide-up">
            <div class="w-full fixed bottom-0 left-0 mobile-cart-link" v-if="transaction.lines.length">
                <router-link :to="{ name: 'checkout.basket', params: {location_slug: location.slug}}"
                             class="cart-link btn h-full cursor-pointer flex justify-between w-full shadow items-center px-4 py-4 text-lg leading-5 font-medium rounded-none focus:outline-none"
                >
                    {{ $t('shoppingcart.mobile.button') }} <span class="ml-2 font-bold">{{
                        $n(transaction.total, currency)
                    }}</span>
                </router-link>
            </div>
        </transition>

        <div class="grid-organizer loader"
             :class="[config.theme.cart_position,
            config.theme.categories_position,
            config.theme.cart_style,
            {'opening-hours': config.theme.show_opening_hours == true },
            {'has-location-image': location.image },
            {'loaded' : loading_animation === false},
            {'items-loading' : items_loaded === false}
            ]"
        >

           <div v-if="location.image"
                id="location-image"
                class="location-image"
                :style="{ backgroundImage: 'url(' + location.image + ')' }"
           >
               <div class="location-image-content">
                   <p class="text-base leading-5 font-bold uppercase opacity-60" v-if="location.city">{{ location.city }}</p>
                   <h1 class="text-3xl leading-7 font-bold text-main">{{ location.name }}</h1>
               </div>
           </div>

            <div class="hide-mobile desktop-categories operative"
                 v-if="categories.length > 1"
                 :class="config.theme.categories_size"
            >
                <!--<div class="font-bold pb-2">{{ $t('menu.categories') }}</div>-->
                <nav id="sidebar-category" class="" aria-label="Sidebar">
                    <div class="sidebar-category-inner radius-anchor"
                         :class="'font-' + config.theme.mobile_category_font_style"

                    >
                        <div v-for="(category, index) in categories" v-bind:key="index">

                            <div v-if="category.items && category.items.length > 0" class="category"
                                 :class="config.theme.category_image_style"
                            >
                                <!-- todo:    Active color category color-->
                                <div v-if="category.items" @click="selectCategory(category)"
                                     class="item btn group text-sm"
                                     :class="category.id == selected_category.id ? 'active' : ''"
                                >
                                    <div class="item-inner">
                                        <img :class="{'opacity-0': !category.attributes.thumbnail_url}"
                                             :src="category.attributes.thumbnail_url"
                                        >
                                        <span class="title">{{ category.attributes.name }}</span>
                                    </div>
                                </div>


                                <div v-for="(subcategory, sub_index) in category.relationships.subcategories.data"
                                     v-bind:key="sub_index"
                                >
                                    <div v-if="subcategory.items" @click="selectCategory(subcategory)"
                                         style="justify-content: center"
                                         class="cursor-pointer group btn flex items-center px-3 py-2 text-sm font-medium rounded-sm item"
                                         :class="subcategory.id == selected_category.id ? 'active' : ''"
                                    >

                                        <div class="item-inner">
                                            <img :class="{'opacity-0': !subcategory.attributes.thumbnail_url}"
                                                 :src="subcategory.attributes.thumbnail_url"
                                            >
                                            <span class="title">{{ subcategory.attributes.name }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>

            <div v-if="config.theme.cart_style === 'cart-fixed' && transaction.lines.length > 0"
                 class="fixed-cart-shortcut"
                 @click="cartToggle()"
            >

                             <span class="cart-counter">
                                {{ transaction.lines.length }}
                            </span>
                <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                     fill="currentColor" stroke="currentColor"
                >
                    <g data-name="Layer 2" fill="currentColor">
                        <g data-name="shopping-cart">
                            <rect width="24" height="24" opacity="0" />
                            <path fill="currentColor" stroke="currentColor"
                                  d="M21.08 7a2 2 0 0 0-1.7-1H6.58L6 3.74A1 1 0 0 0 5 3H3a1 1 0 0 0 0 2h1.24L7 15.26A1 1 0 0 0 8 16h9a1 1 0 0 0 .89-.55l3.28-6.56A2 2 0 0 0 21.08 7z"
                            />
                            <circle fill="currentColor" stroke="currentColor" cx="7.5" cy="19.5" r="1.5" />
                            <circle fill="currentColor" stroke="currentColor" cx="17.5" cy="19.5" r="1.5" />
                        </g>
                    </g>
                </svg>
            </div>

            <div class="products" :class="current_route + ' ' + config.theme.category_background_style">
                <!-- navigation -->
                <!-- <div class="relative z-1 flex items-center justify-end mt-0 search-menu-wrapper"> -->
                <!-- <modal-preorder-activation v-if="menu_function == 'tableservice'" :table_id="transaction.table?.id" -->
                <div class="relative z-40 flex items-center justify-end mt-0 search-menu-wrapper">
                    <modal-preorder-activation v-if="menu_function == 'tableservice'" :table_id="table?.id"
                                               :location_slug="location_slug"
                    />
                    <div class="">
                        <modal-self-scan />
                    </div>

                    <div class="flex-grow">
                        <div class="relative" :class="{'has-input' : searchquery.length}">

                            <input type="search" v-model="searchquery" :placeholder="$t('menu.search')"
                                   class="w-full py-1 px-2 block shadow-sm border rounded-sm outline-none focus:outline-none radius-anchor"
                            />
                            <div class="pointer-events-none absolute inset-y-0 right-2 pr-3 flex items-center">
                                <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                     fill="currentColor" aria-hidden="true"
                                >
                                    <path fill-rule="evenodd"
                                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                          clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="config.theme.product_view_mode !== 'only-list' && config.theme.product_view_mode !== 'only-tiles'"
                        class="ml-4 text-muted cursor-pointer"
                    >

                        <div v-if="items_view === 'list'" class="view-toggle radius-anchor toggle-list"
                             @click="itemViewSet('tiles')"
                        >
                        </div>
                        <div v-if="items_view === 'tiles'" class="view-toggle radius-anchor toggle-tiles"
                             @click="itemViewSet('list')"
                        >
                        </div>
                    </div>
                </div>

                <div id="navigation-category" class="hide-desktop border-0 items-center sticky top-16 z-10"
                     v-if="categories.length > 1"
                     :data-selected="selected_category.id"
                     :class="'mobile-categories-' + config.theme.mobile_category_style"
                >
                    <div id="category-scrollbar"
                         class="w-full whitespace-nowrap flex flex-row overflow-auto navigation-wrapper"
                         ref="mobileCategoryWrapper"
                         :class="'font-' + config.theme.mobile_category_font_style"
                    >
                        <a v-for="(category, index) in categories" v-bind:key="index"
                           @click="selectCategory(category)"
                           class="inline-flex items-center cursor-pointer text-lg rounded mobile-menu-item"
                           :data-anchor="category.id"
                           :class="category.id == selected_category.id ? 'active' : ''"
                        >
                            <div class="px-4 py-2 category-item radius-anchor"
                                 v-if="category.items && category.items.length > 0"
                            >
                                {{ category.attributes.name }}
                                <!-- <span class="ml-2 opacity-60 text-sm">({{ category.items.length }})</span> -->
                            </div>
                        </a>
                    </div>
<!--                    <div class="w-full whitespace-nowrap flex flex-row overflow-auto">-->

<!--                        <div v-for="(subcategory, sub_index) in selected_category.relationships.subcategories.data"-->
<!--                             v-bind:key="sub_index"-->
<!--                        >-->
<!--                            <a v-if="subcategory.items"-->
<!--                               @click="selectCategory(subcategory)"-->
<!--                               class="inline-flex items-center cursor-pointer px-4 py-2 text-base italic rounded"-->
<!--                               :class="subcategory.id == selected_category.id ? 'active' : ''"-->
<!--                            >-->

<!--                                {{ subcategory.attributes.name }}-->
<!--                                &lt;!&ndash; <span class="ml-2 opacity-60 text-sm">({{ category.items.length }})</span> &ndash;&gt;-->
<!--                            </a>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>

                <!-- search -->
                <div class="text-left content-container checkout-box radius-anchor mt-5 payment-table"
                     v-if="transaction.method == 'tableservice' && transaction.table && searchquery === ''"
                >
                    <div class="flex justify-between">
                        <div>
                            <div>{{ $t('order.table.title') }}</div>
                            <div class="font-bold">{{ transaction.table.name }}</div>
                        </div>
                        <h3 v-if="table?.attributes.plug_and_play">
                        </h3>
                    </div>

                    <table-payment-button class="hide-desktop rooted-container mt-4"  v-if="transaction.method == 'tableservice'"/>
                </div>

                <h1 v-if="!filteredProducts" class="pt-5 font-bold text-2xl">
                    {{ $t('products.noproducts') }}
                </h1>

                <!-- items: tiles -->
                <div v-if="items_view === 'tiles'" class="view-tiles products-wrapper"
                     :class="config.theme.mobile_columns + ' ' +
                            config.theme.desktop_columns + ' ' +
                            config.theme.custom_gradient_product_background + ' ' +
                            config.theme.tiles_image_size + ' ' +
                            config.theme.tiles_text_style + ' ' +
                            config.theme.tiles_image_added_padding + ' ' +
                            config.theme.list_add_to_cart_arrangement + ' ' +
                            config.theme.tiles_ordering"
                >
                    <div v-for="(category, index) in filteredCategories" v-bind:key="index">
                        <div v-if="category.items && category.items.length > 0"
                             class="mb-4 category-scroll-wrapper category-background radius-anchor"
                             :class="[{'has-color' : category.attributes.color}, 'ccss-' + category.attributes.name ]"
                             :data-id="category.id"
                        >
                            <div class="background-inner radius-anchor" v-if="category.attributes.color"
                                 :style="{backgroundColor: category.attributes.color}"
                            ></div>

                            <a class="anchor" :ref="category.attributes.slug"></a>

                            <div class="text-left mb-1 sm:mb-3 category-title">
                                <!-- <img v-if="category.attributes.image_url" class="rounded-sm" :src="category.attributes.image_url" /> -->
                                <div class="" :class="{'h-40' : category.image_url}"
                                     :style="{ backgroundImage: 'url(' + category.image_url + ')' }"
                                >
                                    <h1 class="text-2xl font-bold mb-4">{{ category.attributes.name }}</h1>
                                </div>
                            </div>

                            <!-- subcategory -->
                            <div v-for="(subcategory, sub_index) in category.relationships.subcategories.data"
                                 :key="sub_index"
                            >

                                <div v-if="subcategory.items">   <!-- <-- Hier wat gewizigd -->

                                    <a class="anchor" :ref="subcategory.attributes.slug"></a>
                                    <div class="text-left mb-1 sm:mb-3 category-title subcategory-title">
                                        <div class="w-full flex items-center text-center rounded-sm">
                                            <h1 class="text-xl font-bold mb-1">{{ subcategory.attributes.name }}</h1>
                                        </div>
                                    </div>

                                    <div class="product-grid" :class="config.theme.overflow_tiles_size">
                                        <div class="product radius-anchor loader-anchor"
                                             v-for="(item, item_index) in subcategory.items"
                                             v-bind:key="item_index"
                                        >
                                            <menu-item :view="items_view" :initialitem="item"
                                                       :canOrder="canOrder && category.attributes.available_between == false"
                                                       :availableBetween="category.attributes.available_between"
                                                       :category="category"
                                            />
                                            <div class="background-inner-overflow" v-if="category.attributes.color"
                                                 :style="{backgroundColor: category.attributes.color}"
                                            ></div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <!-- items -->
                            <div class="product-grid" :class="config.theme.overflow_tiles_size">

                                <div class="product radius-anchor loader-anchor" v-for="(item, item_index) in category.items"
                                     v-bind:key="item_index"
                                >

                                    <menu-item :view="items_view" :initialitem="item" :cartitems="transaction.lines"
                                               :canOrder="canOrder && category.attributes.available_between == false"
                                               :availableBetween="category.attributes.available_between"
                                               :data-color="category.attributes.color"
                                               :category="category"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- items: list -->

                <div v-if="items_view === 'list'"
                     class="content-container view-list products-wrapper"
                     :class="config.theme.product_style_list_view + ' ' +
                     config.theme.product_full_background_text_position + ' ' +
                     config.theme.custom_gradient_product_background + ' ' +
                     config.theme.list_add_to_cart_arrangement + ' ' +
                     config.theme.desktop_columns"
                >

                    <div v-for="(category, index) in filteredCategories" v-bind:key="index">

                        <div v-if="category.items && category.items.length > 0"
                             class="category-scroll-wrapper category-background radius-anchor"
                             :class="[{'has-color' : category.attributes.color}, 'ccss--' + category.attributes.name ]"
                             :data-id="category.id"
                        >

                            <div class="background-inner radius-anchor" v-if="category.attributes.color"
                                 :style="{backgroundColor: category.attributes.color}"
                            ></div>

                            <a class="anchor" :ref="category.attributes.slug"></a>

                            <div class="text-left mb-1 sm:mb-3">
                                <h1 class="text-2xl font-bold mb-4">{{ category.attributes.name }}</h1>
                                <h3 class="mb-2">{{ category.attributes.description }}</h3>
                            </div>

                            <div class="product-grid">
                                <div class="product radius-anchor loader-anchor" v-for="(item, item_index) in category.items"
                                     v-bind:key="item_index"
                                >
                                    <menu-item :view="items_view" :initialitem="item" :cartitems="transaction.lines"
                                               :canOrder="canOrder && category.attributes.available_between == false"
                                               :availableBetween="category.attributes.available_between"
                                               :category="category"
                                               @load="loaded()"
                                    />
                                </div>
                            </div>

                            <!-- subcategory -->
                            <div v-for="(subcategory, sub_index) in category.relationships.subcategories.data"
                                 :key="sub_index"
                            >
                                <!-- <div v-if="subcategory.items == true"> -->
                                <h1 class="text-lg my-5">{{ subcategory.attributes.name }}</h1>
                                <a class="anchor" :ref="subcategory.attributes.slug"></a>

                                <div v-if="subcategory.items" class="product-grid" :class="config.theme.overflow_tiles_size">

                                    <div class="product radius-anchor loader-anchor" v-for="(item, item_index) in subcategory.items"
                                         v-bind:key="item_index"
                                    >
                                        <menu-item :view="items_view" :initialitem="item" :cartitems="transaction.lines"
                                                   :canOrder="canOrder && category.attributes.available_between == false"
                                                   :availableBetween="category.attributes.available_between"
                                                   :category="category"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="hide-mobile order-overview overflow-y-auto cart-background" v-if="canOrder">

                <table-payment-button :table="transaction.table" v-if="transaction.method == 'tableservice'" />

                <!-- <shopping-cart /> -->
                <basket :location_slug="location_slug" :edit="true" :tip="true" />

                <div class="mt-5 content-container border text-sm radius-anchor checkout-box">
                    <h2 class="font-bold mb-3 capitalize text-base text-2xl">{{
                            $t('methods.' + transaction.method)
                        }}</h2>
                    <div class="mb-3" v-if="transaction.account">Account: <span class="font-bold"
                    >{{ transaction.account?.name }}</span></div>


                    <!-- table -->
                    <template v-if="table">
                        <div class="mb-3" v-if="table?.attributes.name">
                            {{ $t('fields.table') }}:
                            <span class="font-bold">{{ table?.attributes.name }}</span>
                        </div>

                        <div class="mb-3" v-if="table?.attributes.plug_and_play">

                            Geopend om: 
                            <span class="font-bold">{{ table?.attributes.activated_at }}</span>
                        </div>

                    </template>

                    <div v-if="transaction.method == 'takeaway'">
                        <div>{{ location.name }}</div>
                        <div>{{ location.streetname }} {{ location.housenumber }}</div>
                        <div>{{ location.zipcode }} {{ location.city }}</div>
                    </div>
                    <div v-if="transaction.method == 'delivery' && transaction.delivery_address">
                        <div>{{ transaction.delivery_address.streetname }} {{
                                transaction.delivery_address.housenumber
                            }}
                        </div>
                        <div>{{ transaction.delivery_address.zipcode }} {{ transaction.delivery_address.city }}</div>
                    </div>

                    <!-- <modal-shipping-cart /> -->
                </div>
            </div>
            <div class="blurred-area" @click="cartToggle()"></div>
        </div>
    </div>

    <!-- modal-menu-date -->
    <modal-menu-date @reload-menu="loadMenu()" :location_slug="location_slug" />

</template>

<style>
.anchor {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}
</style>

<script>
// libs
import * as _api from '../lib/api';
import * as _location from "../lib/location";
import * as _state from "../lib/state";
import * as _table from "../lib/table";
import * as _transaction from "../lib/transaction";

// components
import MenuItem from '../components/MenuItem';

import TablePaymentButton from '../components/TablePaymentButton';
import Basket from "../components/Basket";



// modals
import ModalMenuDate from '../components/ModalMenuDate.vue';
import ModalSelfScan from '../components/ModalSelfScan.vue';
// import ModalShoppingCart from '../components/ModalShoppingCart';
import ModalPreorderActivation from '../components/ModalPreorderActivation.vue';

export default {
    name: 'Menu',
    props: ['location_slug'],
    components: {
        MenuItem,
        TablePaymentButton,
        ModalMenuDate,
        // ModalShoppingCart,
        ModalSelfScan,
        ModalPreorderActivation,
        Basket,
    },
    data () {
        return {
            loading: true,
            searchquery: '',
            menu_function: '',
            selected_category: {},
            selected_item: null,
            categories: {
                items: [],
            },
            offsetCorrection: 74,
            offsetCorrected: false,
            locationImageHeight: 0,
            items: {},
            scrollPosition: null,
            previousScrollPosition: 0,
            links: {},
            sections: {},
            items_view: 'list',
            loading_animation: true,
            items_loaded: false,
        };
    },
    async mounted () {
        
        _table.refresh();

        // todo: move function tableclear
        _transaction.setMethod(this.$route.name);
        // set the menu function
        // this.menu_function = this.$route.name;

        // clear the table
        if (this.transaction.method == 'delivery' && this.transaction.method_delivery != 'tables') {
            _transaction.setTable(false);
        }
        if ((['takeaway', 'quickorder']).includes(this.transaction.method)) {
            _transaction.setTable(false);
        }

        if (!this.location.slug) {
            await _location.init(this.location_slug);
        }

        if (this.config.theme.product_view_mode === 'only-list' || this.config.theme.product_view_mode === 'start-with-list') {
            this.items_view = 'list';
        } else {
            this.items_view = 'tiles';
        }


        setTimeout(
            //page loading
            this.toggleLoading
        , 1200);

        if (window.innerWidth > 767) {
            var desktopWrapper = document.querySelector('.grid-organizer');

            if (desktopWrapper) {
                this.offsetCorrection -= 52;

                if (desktopWrapper.classList.contains('categories-top')) {
                    //-10 for readable offset
                    this.offsetCorrection = document.querySelector('.grid-organizer .products').offsetTop;
                }
            }
        }

        this.loadMenu();
        window.addEventListener('scroll', this.scroll);
    },

    updated() {
        this.$nextTick(function () {
            setTimeout(
                //items loading
                this.setItemsLoaded
            , 1200);
        })
    },

    methods: {
        toggleLoading() {
            this.loading_animation = !this.loading_animation;

            if (document.getElementById('location-image')) {
                this.offsetCorrection -= document.getElementById('location-image').offsetHeight;
            }
        },

        setItemsLoaded() {
            this.items_loaded = true;
        },

        scroll () {
            if (scrollY >= (this.locationImageHeight + 100)) {
                document.body.classList.add('scrolling');
            } else {
                document.body.classList.remove('scrolling');
            }

            let scrolling = true;

            if (this.previousScrollPosition > scrollY) {
                scrolling = false;
            }

            // works fine when scrolling down, no work when scrolling up
            for (let index = 0; index < this.sections.length; index++) {
                let section = this.sections[index];

                if (scrollY >= (section.offsetTop - this.offsetCorrection) && scrollY < (section.offsetTop - this.offsetCorrection) + section.offsetHeight) {
                    const newActiveCategory = this.links[index];
                    const newActiveId = parseInt(newActiveCategory.parentElement.dataset.anchor);

                    if (newActiveId !== parseInt(this.selected_category.id)) {

                        this.categories.forEach(category => {
                            if (parseInt(category.id) === newActiveId) {
                                this.selectCategory(category, scrolling);
                            }
                        });
                    }
                }
            }

            this.previousScrollPosition = scrollY;
        },

        calcOffset (index) {
            let offset = 0;
            for (let i = 0; i < index; i++) {
                offset += this.links[i].offsetWidth;
            }
            return offset;
        },

        loadMenu () {
            this.getCategories();
            this.getItems();
        },

        cartToggle () {
            let cartElement = document.querySelector('#app .cart-fixed');

            if (cartElement) {
                cartElement.classList.toggle('cart-open');
            }
        },

        rateToPrice (rate) {
            return '€ ' +  parseFloat(rate).toFixed(2);
        },

        async getCategories () {
            this.loading = true;
            // todo loading spinner
            this.categories = await _api.get('categories');

            if (this.categories.length > 0) {
                this.selectCategory(this.categories[0]);
            }

            this.loading = false;
        },

        async getItems () {

            // todo loading spinner
            this.items = await _api.get('items');

            if (!this.items.length > 0) {
                this.loading = false;
                return;
            }

            for (let item of this.items) {

                for (let category of this.categories) {

                    // if (Object.values(item.attributes.category_ids).includes(parseInt(category.id))) {
                    if (item.attributes.category_id == category.id) {
                        if (!category.items) {
                            category.items = [];
                        }
                        // is item a dailyitem, then set item to the daily item
                        let insert_item = item;
                        if (item.attributes.dailyitems) {
                            if (item.attributes.dailyitems[this.transaction.menu_day]) {
                                insert_item = item.attributes.dailyitems[this.transaction.menu_day];
                            }
                        }
                        category.items.push(insert_item);
                    }

                    for (let subcategory of category.relationships.subcategories.data) {
                        // if (item.attributes.category_ids == category.id ) {
                        // if (Object.values(item.attributes.category_ids).includes(parseInt(category.id))) {
                        if (Object.values(item.attributes.category_ids).includes(parseInt(subcategory.id))) {

                            if (!subcategory.items) {
                                subcategory.items = [];
                            }

                            let insert_item = item;
                            if (item.attributes.dailyitems) {
                                if (item.attributes.dailyitems[this.transaction.menu_day]) {
                                    insert_item = item.attributes.dailyitems[this.transaction.menu_day];
                                }
                            }

                            subcategory.items.push(insert_item);

                            // subcategory.items.push(item.attributes.dailyitems[this.transaction.menu_day] ?? item)
                        }
                    }
                }
            }

            this.links = document.getElementsByClassName('category-item');
            this.sections = document.getElementsByClassName('category-scroll-wrapper');
            this.loading = false;
        },

        selectCategory (category, scroll = true) {
            this.selected_category = category;

            // scroll
            var element = this.$refs[category.attributes.slug];

            if (element && element[0]) {
                var elementToScrollToId = element[0].parentElement.dataset.id;
                var elementToScrollTo = document.querySelector('[data-id="' + elementToScrollToId + '"]');
                var elementToScrollToNavigation = document.querySelector('[data-anchor="' + elementToScrollToId + '"]');
                var topScrollBar = document.getElementById('category-scrollbar');
                let leftScrollPosition = elementToScrollToNavigation.offsetLeft - 16;

                topScrollBar.scrollTo({
                    left: leftScrollPosition,
                    behavior: 'smooth',
                });

                // todo: should be in mounted but can't access refs
                if (this.offsetCorrected === false) {
                    if (document.querySelector('.extra-padding-products')) {
                        this.offsetCorrection -= 36;
                    }

                    if (getComputedStyle(this.$refs.mobileCategoryWrapper).paddingBlockStart.length) {
                        this.offsetCorrection += (parseInt(getComputedStyle(this.$refs.mobileCategoryWrapper).paddingBlockStart, 10) * 2);
                    }

                    this.offsetCorrected = true;
                }

                var top = elementToScrollTo.offsetTop - this.offsetCorrection;

                if (scroll === true) {
                    window.scrollTo(0, top);
                }
            }
        },

        scrollMeTo (refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;

            window.scrollTo(0, top);
        },

        itemViewSet (view) {
            this.items_view = view;

            this.items_loaded = false;
        },

        filterItems (searchquery, items) {
            return items.filter(function (item) {
                const description = (item.attributes.webshop_description ? item.attributes.webshop_description : item.attributes.description).toLowerCase();

                return (description.indexOf(searchquery.toLowerCase()) !== -1);
            });
        },
    },

    computed: {
        current_route () {
            return this.$route.name;
        },

        filteredCategories: function () {
            let categories = JSON.parse(JSON.stringify(this.categories));

            if (!this.searchquery) {
                return categories;
            }

            for (let category of categories) {
                if (category.items) {
                    category.items = this.filterItems(this.searchquery, category.items);

                    for (let subcategory of category.relationships.subcategories.data) {
                        if (subcategory.items) {
                            subcategory.items = this.filterItems(this.searchquery, subcategory.items);
                        }
                    }
                }
            }

            return categories;
        },

        filteredProducts () {
            let categories = this.filteredCategories;
            let products = [];

            for(let i = 0; i< categories.length; i++) {

                if (categories[i].items) {
                    categories[i].items.forEach(function (elem) {
                        products.push(elem);
                    });
                }

                if (categories[i].relationships.subcategories.data && categories[i].relationships.subcategories.data.items) {
                    categories[i].relationships.subcategories.data.items.forEach(function(elem) {
                        products.push(elem);
                    });
                }
            }

            return products.length;
        },

        location () {
            return _state.get('location/getLocation');
        },

        config () {
            return _state.get('config/getConfig');
        },
        currency (){
            return this.config.settings.currency;
        },
        canOrder () {
            // todo
            // if (this.table && this.table.attributes.paymenttype === 'paymentonly') {
            //     return false;
            // }

            let functions = ['delivery', 'takeaway', 'quickorder', 'tableservice'];

            return functions.includes(this.$route.name);
        },

        transaction () {
            return _state.get('transaction/getTransaction');
        },

        table () {
            return _table.get();
        },
    },
};
</script>
