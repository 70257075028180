<template>
    <div class="h-full">

        <!-- view: tiles -->
        <div v-if="view == 'tiles'" class="h-full">

            <div v-if="initialitem.attributes.available_in_inventory"
                 class="content-container justify-start radius-anchor"
            >

                <div @click="addItem(true)" class="w-100">
                    <div class="img-wrapper radius-anchor"
                         :class="'image-' + config.theme.tiles_image_style + ' ' + config.theme.category_image_option + ' ' +
                            'fallback-' + config.theme.fallback_tiles_image_style"
                         v-if="config.theme.tiles_image_size !== 'tiles-image-hide'"
                    >

                        <img v-if="productImage" :src="productImage" class="radius-anchor"
                             :class="{'fallback': config.settings.logo === productImage}"
                        >

                        <inline-svg
                            v-else-if="config.theme.category_image_option === 'category-svg' && category.attributes.svg_url"
                            :src="category.attributes.svg_url"
                            class="inline-svg-category"
                        >
                        </inline-svg>
                    </div>

                    <div class="product-content">
                        <div class="my-2">
                            <h3 class="title font-bold mt-4 text-ellipsis">{{
                                    initialitem.attributes.webshop_description
                                }}</h3>
                            <p v-if="initialitem.attributes.description_long" class="truncate">
                                {{ initialitem.attributes.description_long }}</p>
                        </div>
                    </div>
                </div>

                <span class="text-xs" v-if="availableBetween">
                    <div>{{ $t('menu.item.available') }}:</div>
                    <div> {{ availableBetween }}</div>
                </span>

                <div class="amount-price-wrapper add-item-wrapper" v-if="canOrder">

                    <div class="price">
                        {{ $n(initialitem.attributes.webshop_price, currency) }}
                    </div>

                    <div class="amount text-center mt-2">
                        <button class="add-item minus" v-if="quantityInCart > 0"
                                @click="processRemoveItem(initialitem)"
                                :class="{'trash': quantityInCart === 1}"
                        >

                        </button>
                        <span v-if="quantityInCart">
                            {{ quantityInCart }}
                        </span>
                        <button type="button" @click="addItem()" class="add-item plus">
                        </button>
                    </div>
                </div>
            </div>

            <!-- not available -->
            <div v-else class="content-container opacity-50 justify-start">
                <div>
                    <div class="img-wrapper radius-anchor"
                         :class="'image-' + config.theme.tiles_image_style + ' ' + config.theme.category_image_option"
                         v-if="config.theme.tiles_image_size !== 'tiles-image-hide'"
                    >

                        <img
                            v-if="config.theme.category_image_option === 'product-image' && initialitem.attributes.thumbnail_url"
                            :src="initialitem.attributes.thumbnail_url" class="radius-anchor"
                        >
                        <img
                            v-else-if="config.theme.category_image_option === 'category-image' && category.attributes.thumbnail_url"
                            :src="category.attributes.thumbnail_url" class="radius-anchor"
                        >

                        <inline-svg
                            v-else-if="config.theme.category_image_option === 'category-svg' && category.attributes.svg_url"
                            :src="category.attributes.svg_url"
                            class="inline-svg-category"
                        >
                        </inline-svg>
                    </div>

                    <div class="product-content">
                        <div class="my-2">
                            <h3 class="title font-bold mt-4 text-ellipsis">{{
                                    initialitem.attributes.webshop_description
                                }}</h3>
                            <p v-if="initialitem.attributes.description_long" class="truncate">
                                {{ initialitem.attributes.description_long }}</p>
                        </div>
                    </div>
                </div>
                <div class="amount-price-wrapper add-item-wrapper" v-if="canOrder">
                    <div class="text-xs italic price">{{ $t('menu.item.outofstock') }}</div>
                </div>
            </div>
        </div>

        <!-- view: list -->
        <div v-if="view == 'list'">
            <div v-if="canOrder" class="">
                <div class="">
                    <div class="product-list-item radius-anchor"
                         :class="'description-' + config.theme.long_description_layout"
                    >

                        <div class="img-wrapper radius-anchor"
                             :class="'image-' + config.theme.list_image_style + ' ' + config.theme.category_image_option + ' ' +
                                'fallback-' + config.theme.fallback_list_image_style"
                             v-if="config.theme.product_style_list_view !== 'list-hide-image'"
                        >

                            <img v-if="productImage" :src="productImage" class="radius-anchor"
                                 :class="{'fallback': config.settings.logo === productImage}"
                            />
                            <inline-svg
                                v-else-if="config.theme.category_image_option === 'category-svg' && category.attributes.svg_url"
                                :src="category.attributes.svg_url"
                                class="inline-svg-category"
                            >
                            </inline-svg>
                        </div>

                        <div class="product-content" :class="{ 'description-open': description_open }">
                            <div class="fold-out-toggle"
                                 v-if="config.theme.long_description_layout === 'fold-out'
                                 && config.theme.list_add_to_cart_arrangement !== 'tile-clickable'
                                 && initialitem.attributes.webshop_description_long"
                                 @click="description_open = !description_open"
                            >
                            </div>
                            <div>
                                <h3 class="title font-bold">{{ initialitem.attributes.webshop_description }}</h3>
                                <p v-if="initialitem.attributes.webshop_description_long" class="description">
                                    {{ initialitem.attributes.webshop_description_long }}</p>
                            </div>
                            <div class="col-span-1 price menu_items_price">
                                <div class="font-bold">{{ $n(initialitem.attributes.webshop_price, currency) }}</div>
                            </div>

                            <div class="col-span-1 text-xs sold-out add-item-wrapper"
                                 v-if="!initialitem.attributes.available_in_inventory"
                            >
                                {{ $t('menu.item.outofstock') }}
                            </div>
                            <div v-else class="flex add-item-wrapper">

                                <button class="add-item minus" v-if="quantityInCart > 0"
                                        @click="processRemoveItem(initialitem)"
                                    :class="{'trash': quantityInCart === 1}"
                                >

                                </button>
                                <span v-if="quantityInCart">
                                    {{ quantityInCart }}
                                </span>
                                <button type="button" @click="addItem()" class="add-item plus">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="h-10 flex justify-center" v-if="canOrder">
                    <span class="inline-flex">
                        <button type="button" @click="addItem()" class="inline-flex items-center justify-center w-full btn btn-primary-outline  px-4 py-2 text-base leading-6 font-medium shadow-sm focus:outline-none">
                            € {{ $n(initialitem.attributes.webshop_price) }}
                            <svg class="ml-3 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                <path fill-rule="evenodd" d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 7C11 6.44772 10.5523 6 10 6C9.44772 6 9 6.44772 9 7V9H7C6.44772 9 6 9.44771 6 10C6 10.5523 6.44772 11 7 11H9V13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13V11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H11V7Z" clip-rule="evenodd"/>
                            </svg>
                        </button>
                    </span>
                </div> -->

            </div>

            <!-- not available -->
            <div v-else class="">
                <div class="grid grid-cols-5">
                    <div class="col-span-4">
                        <h3 class="truncate font-bold">{{ initialitem.attributes.webshop_description }}</h3>
                        <p v-if="initialitem.attributes.webshop_description_long">
                            {{ initialitem.attributes.webshop_description_long }}</p>

                        <div class="col-span-1 text-xs" v-if="availableBetween">
                            {{ $t('menu.item.available') }}: {{ availableBetween }}
                        </div>
                    </div>
                    <div class="col-span-1 text-right menu_items_price">
                        <div class="font-bold"> {{ $n(initialitem.attributes.webshop_price, currency) }}</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- todo: move to own component -->
        <!-- modal -->
        <transition name="fade">
            <div v-if="show_modal" class="fixed z-50 inset-0 overflow-y-auto">
                <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                    <div class="fixed inset-0 transition-opacity">
                        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                    </div>
                </div>
            </div>
        </transition>

        <transition name="slide-up">
            <div id="modal" v-if="show_modal"
                 class="fixed z-50 modal-full align-bottom overflow-hidden md:my-8 md:align-middle md:max-w-lg radius-anchor"
                 role="dialog" aria-modal="true" aria-labelledby="modal-headline"
            >
                <!-- <div id="modal" class="w-full inline-block align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg" role="dialog" aria-modal="true" aria-labelledby="modal-headline"> -->
                <!--                <div class="hidden sm:block modal-close">-->
                <!--                    <button @click="closeModal()" type="button" class="">-->
                <!--                        <span class="sr-only">Close</span>-->
                <!--                        &lt;!&ndash; Heroicon name: x &ndash;&gt;-->
                <!--                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">-->
                <!--                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />-->
                <!--                        </svg>-->
                <!--                    </button>-->
                <!--                </div>-->
                <div id="modal-content" class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-col">

                    <div class="flex-grow relative overflow-y-auto"
                    :class="{'has-description' : item.attributes.webshop_description_long,
                    'has-image': item.attributes.image_url,
                    'img-full-screen': imgFullScreen,
                    'in-step' : step == 'variants' || step == 'addons' || step == 'kitchen_groceries',
                    'show-details' : showDetails}
                    ">

                        <!-- Details -->
                        <div class="details" v-if="item.attributes.image_url || item.attributes.webshop_description_long">
                            <div class="modal-content-image-wrapper" v-if="item.attributes.image_url">
                                <img
                                    @load="imgLoaded()"
                                    class="radius-anchor"
                                    :class="{'img-loaded': popupImgLoaded === true}"
                                    :src="item.attributes.image_url"
                                >
                                <div v-if="item.attributes.webshop_description_long"
                                     @click="imgFullScreen = !imgFullScreen"
                                     class="btn btn-primary btn-full-screen"></div>
                            </div>
                            <div class="description-wrapper">
                                <p v-if="item.attributes.webshop_description_long">
                                    {{ item.attributes.webshop_description_long }}
                                </p>
                            </div>
                        </div>

                        <!-- Variants -->
                        <div v-if="step == 'variants'">
                            <div class="mt-2 flex justify-center border rounded-smp-5" v-if="variant">
                                <img class="max-h-20 radius-anchor" :src="variant.attributes.thumbnail_url" />
                            </div>
                            <div class="flex justify-center mt-4">
                                <span class="relative z-0 inline-flex addon-options">
                                    <div v-for="(item_variant, variant_index) in item.attributes.variants"
                                         :key="variant_index"
                                    >
                                        <button type="button" @click="variantSelect(item_variant)"
                                                class="relative btn"
                                                :class="item_variant.id == (variant ? variant.id : null) ? 'btn-primary' : 'btn-primary-outline'"
                                        >
                                            <span>
                                                {{ item_variant.attributes.label }}
                                            </span>
                                            <span>
                                                €{{ item_variant.attributes.price }}
                                            </span>
                                        </button>
                                    </div>
                                </span>
                            </div>
                            <div class="text-xs text-red-600" v-if="errors.variants && !variant">verplicht</div>
                        </div>

                        <!-- Addons -->
                        <div v-if="step == 'addons'">
                            <div class="mb-3" v-for="(addon, addon_index) in addons" :key="addon_index">
                                <div class="font-bold flex justify-center mb-8 text-3xl">
                                    <div>
                                        {{ addon.description }}
                                        <span v-if="addon.quantity_min > 0">*</span>
                                    </div>
                                    <div>
                                        <!-- <div>{{ option.description }} ({{ option.price }})</div> -->
                                        <div class="text-xs text-red-600" v-if="addon.error">
                                            selecteer
                                            <span v-if="addon.quantity_min && addon.quantity_max"
                                            >minimaal {{ addon.quantity_min }} en maximaal {{
                                                    addon.quantity_max
                                                }}</span>
                                            <span v-else-if="addon.quantity_min"
                                            >minimaal {{ addon.quantity_min }}</span>
                                            <span v-else-if="addon.quantity_max">maximaal {{
                                                    addon.quantity_max
                                                }}</span>
                                        </div>
                                    </div>
                                    <!-- <span class="text-xs text-red-600" v-if="hasError('addons', addon.description)">verplicht</span> -->
                                </div>

                                <div class="mb-3" v-for="(option, option_index) in addon.options" :key="option_index">
                                    <!-- Quantity -->
                                    <div v-if="addon.type == 'buttons'" class="flex justify-start items-center">
                                        <div class="flex rounded-sm  items-center justify-end mr-5 item-amount-wrapper">
                                            <div class="add-item btn">
                                                <div @click="changeAddonQuantity(option, -1, addon)" class="block p-1">
                                                    -
                                                </div>
                                            </div>
                                            <div class="px-5 add-item-counter">
                                                {{ parseInt(option.quantity) }}
                                            </div>
                                            <div class="add-item btn">
                                                <div @click="changeAddonQuantity(option, 1, addon)" class="block p-1">
                                                    +
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex justify-between items-center w-full">
                                            <div>
                                                <div>{{ option.description }} ({{ $n(option.price, currency) }})</div>
                                                <div class="text-xs text-red-600" v-if="option.error">
                                                    <span v-if="option.quantity_min && option.quantity_max"
                                                    >minimaal {{ option.quantity_min }} en maximaal {{
                                                            option.quantity_max
                                                        }}</span>
                                                    <span v-else-if="option.quantity_min"
                                                    >minimaal {{ option.quantity_min }}</span>
                                                    <span v-else-if="option.quantity_max"
                                                    >maximaal {{ option.quantity_max }}</span>
                                                </div>
                                            </div>
                                            <div>{{ $n(option.quantity * option.price, currency) }}</div>
                                        </div>
                                    </div>

                                    <!-- Radio -->
                                    <div v-if="addon.type == 'radio'" class="flex justify-between items-center">
                                        <div class="flex items-center justify-start gap-5 custom-input">
                                            <input @change="addonRadioSelect(addon, option)" type="radio"
                                                   :id="option.id" :name="addon.id" :value="option.id"
                                                   class="focus:ring-0"
                                            >
                                            <label :for="option.id">{{ option.description }}
                                                ({{ $n(option.price, currency) }})</label>
                                        </div>
                                        <div v-if="option.quantity > 0">
                                            {{ $n(option.quantity * option.price, currency) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Kitchen Groceries -->
                        <div v-if="step == 'kitchen_groceries'">
                            <div class="my-3"
                                 v-for="(kitchen_grocery, kitchen_grocery_index) in item.attributes.kitchen_groceries"
                                 :key="kitchen_grocery_index"
                            >
                                <fieldset>
                                    <legend class="w-full font-bold flex justify-between">
                                        <span>{{ kitchen_grocery.description }} *</span>
                                        <span class="text-xs text-red-600"
                                              v-if="hasError('kitchen_groceries', kitchen_grocery.description)"
                                        >verplicht</span>
                                    </legend>
                                    <ul class="space-y-1 addon" role="radiogroup" aria-labelledby="radiogroup-label">
                                        <li v-for="(option, option_index) in kitchen_grocery.options.split(';')"
                                            :key="option_index" tabindex="0" role="radio"
                                            class="group relative bg-white rounded-sm shadow-sm cursor-pointer focus:outline-none"

                                        >
                                            <div @click="setKitchenGrocery(kitchen_grocery.description, option)"
                                                 class="btn w-full"
                                                 :class="{'btn-primary': isKitchenGrocerySelected(kitchen_grocery.description, option), 'btn-secondary': !isKitchenGrocerySelected(kitchen_grocery.description, option)}"
                                            >
                                                {{ option }}
                                            </div>
                                        </li>
                                    </ul>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    <div class="sm:flex sm:items-start relative addon-modal-title">
                        <div class="mt-3 text-center sm:mt-0 sm:text-left px-6 sm:px-0">
                            <h3 class="text-lg leading-6 font-bold text-2xl text-gray-900" id="modal-headline">
                                {{ item.attributes.webshop_description }}

                                <div class="fold-out-toggle"
                                     v-if="(step == 'variants' || step == 'addons' || step == 'kitchen_groceries') && (item.attributes.webshop_description_long || item.attributes.image_url)"
                                     @click="showDetails = !showDetails"></div>
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="content-footer p-4 sm:p-6 sm:flex sm:justify-between items-center">

                    <!-- Quantity -->
                    <div
                        class="flex rounded-sm sm:mb-0 mb-4 items-center justify-center sm:justify-end item-amount-wrapper"
                        v-if="canOrder"
                    >
                        <div class="">
                            <button type="button" @click="subQuantity()" class="rounded-sm border p-1 add-item btn">
                                <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M3 10C3 9.44772 3.44772 9 4 9L16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11L4 11C3.44772 11 3 10.5523 3 10Z"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div class="text-main px-5 item-quantity">
                            {{ quantity }}
                        </div>
                        <div>
                            <button type="button" @click="addQuantity()" class="rounded-sm border p-1 add-item btn">
                                <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div class="sm:flex sm:flex-row-reverse">
                        <span class="flex w-full sm:ml-3 sm:w-auto" v-if="canOrder">
                            <button v-if="step == 'details'" type="button" @click="nextStep('details')"
                                    class="btn btn-confirm"
                            >
                                {{ $t('modal.item.button.confirm') }}
                            </button>
                            <button v-if="step == 'variants'" type="button" @click="confirmVariant()"
                                    class="btn btn-confirm"
                            >
                                {{ $t('modal.item.button.confirm') }}
                            </button>
                            <button v-if="step == 'addons'" type="button" @click="confirmAddons()"
                                    class="btn btn-confirm"
                            >
                                {{ $t('modal.item.button.confirm') }}
                            </button>
                            <button v-if="step == 'kitchen_groceries'" type="button" @click="confirmKitchenGroceries()"
                                    class="btn btn-confirm"
                            >
                                {{ $t('modal.item.button.confirm') }}
                            </button>
                            <!-- <button type="button" @click="addItemToCart()" class="inline-flex justify-center w-full rounded-sm px-4 py-2 btn btn-primary text-base leading-6 font-medium shadow-sm focus:outline-none sm:text-sm sm:leading-5">
                                Toevoegen
                            </button> -->
                        </span>
                        <span class="mt-3 flex w-full rounded-sm shadow-sm sm:mt-0 sm:w-auto">
                            <button type="button" @click="closeModal()" class="btn btn-close">
                                {{ $t('modal.item.button.close') }}
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<style scoped>
body {
    position: fixed;
}


</style>

<script>

import ItemHelper from '../mixins/item.helper.js';
import * as _state from "../lib/state";
import InlineSvg from 'vue-inline-svg';
import { subtractQuantity } from "../lib/transaction";

export default {
    name: 'MenuItem',
    props: ['view', 'initialitem', 'canOrder', 'availableBetween', 'category'],
    mixins: [ItemHelper],
    components: {
        InlineSvg,
    },
    data () {
        return {
            popupImgLoaded: false,
            description_open: false,
            imgFullScreen: false,
            showDetails: false,
        };
    },
    methods: {
        imgLoaded () {
            this.popupImgLoaded = true;
        },

        processRemoveItem(item) {
            if (this.quantityInCart >= 1) {
                this.transaction.lines.reverse().forEach(function(line) {
                    if (parseInt(line.item_id) === parseInt(item.id)) {
                        subtractQuantity(line);
                    }
                });
            }
        }
    },
    computed: {
        quantityInCart () {
            let id = this.initialitem.id;
            let quantity = 0;

            this.transaction.lines.forEach(function (element) {
                if (element.item_id === id) {
                    quantity = element.quantity;
                }
            });
            return quantity;
        },

        productImage () {
            if (this.config.theme.category_image_option === 'product-image') {
                if (this.initialitem.attributes.thumbnail_url) {
                    return this.initialitem.attributes.thumbnail_url;
                }

                // Fallback
                return this.config.settings.logo;
            }

            if (this.config.theme.category_image_option === 'category-image') {
                if (this.category.attributes.thumbnail_url) {
                    return this.category.attributes.thumbnail_url;
                }

                // Fallback
                return this.config.settings.logo;
            }

            return false;
        },

        config () {
            return _state.get('config/getConfig');
        },

        transaction () {
            return _state.get('transaction/getTransaction');
        },
        currency (){
            return this.config.settings.currency;
        }
    },
};
</script>
