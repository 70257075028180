<template>
    <div>
        <transition name="blur-background">
            <blur v-if="show" @click="close();">
            </blur>
        </transition>

        <transition name="modal-small">
            <div class="fixed z-100 w-full inset-0 overflow-y-auto ccss--modal-hourly-rate-confirm fixed-modal-wrapper" v-if="show">
                <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                    <div
                        class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full radius-anchor"
                        role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                    >
                        <div class="modal-close">
                            <a @click="close()" class="modal-close" aria-label="Close">
                                <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </a>
                        </div>

                        <div class="pt-8 pb-3 p-8">
                            <div class="text-center sm:text-left py-8 px-4 sm:rounded-sm sm:px-10 pt-0">

                                <h2 class="text-center text-3xl font-extrabold">Tafel afrekenen</h2>
                                <p class="mt-2 text-center text-sm max-w"
                                    v-if="table.attributes.hourly_rate_payment_in_webshop == true"
                                >
                                    Door af te rekenen ga je naar de checkout en sluit je de tafel. De fee voor de tafel wordt opnieuw berekend en bijgevoegd in de transactie.
                                    Je hebt 5 minuten om je betaling af te ronden, daarna wordt je totaal opnieuw berekend.
                                </p>

                                <p v-else class="mt-2 text-center text-sm max-w">
                                    Ga naar de kassa om af te rekenen.
                                </p>
                                
                            </div>

                            <div class="current-hourly-rate-data radius-anchor">
                                <h3 class="text-xl">Huidige tijd</h3>
                                <div> <span class="font-bold">Tijd gezeten:</span> {{ hoursToHoursAndMinutes(hourlyRateConstruct?.hoursDifference) }} uur</div>
                                <div><span class="font-bold">Uurtarief:</span> {{ rateToPrice(hourlyRateConstruct?.hourlyRate) }}</div>
                                <div><span class="font-bold">Prijs:</span> {{ rateToPrice(hourlyRateConstruct?.totalPrice) }}</div>
                            </div>
                        </div>
                            
                        <div class="p-4" v-if="table.attributes.hourly_rate_payment_in_webshop == true">
                            <button type="submit"
                                    @click="toCheckout()"
                                    class="btn btn-primary font-bold inline-flex w-full justify-center items-center px-6 py-3 text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150"
                            >
                                Afrekenen
                            </button>
                        </div>
                        <div v-else class="p-4"></div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>

import * as _api from '../lib/api';
import * as _table from '../lib/table';
import * as _transaction from '../lib/transaction';
import * as _state from "../lib/state";
import * as _applicationState from '../lib/application-state';
import moment from 'moment';

import Blur from "./Blur";
import ItemHelper from '../mixins/item.helper.js';

export default {
    name: 'ModalHourlyRateConfirm',
    mixins: [ItemHelper],
    components: {
        Blur,
    },
    data () {
        return {
            show: false,
            hourlyRateConstruct: null,
        };
    },
    methods: {
        async open () {
            this.show = true;

            let timer = await _table.getBasketTimeData()

            // if basket timer has expired reset the hoursDifferenceState
            if (timer.expired == true) {
                _applicationState.setHoursDifference(null);
            }

            this.hourlyRateConstruct = await _table.constructHourlyRate(_applicationState.getHoursDifference());
        },

        close () {
            this.show = false;
        },

        hoursToHoursAndMinutes(hours) {
            var num = hours;
            var rhours = Math.floor(hours);
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            return ('0' + rhours).slice(-2) + ":" + ('0' + rminutes).slice(-2)
        },

        rateToPrice (rate) {
            return '€ ' +  parseFloat(rate).toFixed(2);
        },

        resetHourlyRateStates() {
            if (!_applicationState.getBasketStartTime()) {
                _applicationState.setBasketStartTime(new Date());
            }

            if (!_applicationState.getHoursDifference()) {
                _applicationState.setHoursDifference(null);
            }
        },

        toCheckout() {
            this.resetHourlyRateStates();

            // go to checkout
            this.$router.push(
                { 
                    name: 'checkout.table', params: {location_slug: this.location_slug }
                }
            ); 
        },
    },
    computed: {
        location_slug () {
            return this.$route.params.location_slug;
        },

        transaction () {
            return _state.get('transaction/getTransaction');
        },

        table() {
            return _table.get();
        },
    },
};
</script>
