<template>
    <div>
        <transition name="blur-background">
            <blur v-if="active">
            </blur>
        </transition>

        <transition name="modal-small">
            <div v-if="active" class="fixed inset-0 overflow-y-auto modal-z-index ccss--modal-menu-date fixed-modal-wrapper">
                <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                    <div class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full radius-anchor" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                        <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div class="sm:flex sm:items-start">
                                <div class="text-center mx-auto">
                                    <h3 class="text-lg leading-6 font-bold text-2xl text-gray-900" id="modal-headline">
                                        {{ $t('delivery_method.pick_date') }}
                                    </h3>
                                    <div class="mt-10">
                                        <div class="flex justify-center">
                                            <v-date-picker :disabled-dates="unavailable_dates" :min-date="new Date()" v-model="form.date" :attributes="datepicker_attributes" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="px-4 py-4 sm:flex sm:flex-row-reverse">
                            <span class="flex w-full rounded-sm shadow-sm sm:mt-0">
                                <a @click="submit()" type="button" class="btn btn-primary inline-flex w-full justify-center items-center px-6 py-3 text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                                    {{ $t('delivery_method.button.confirm') }}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

// lib
import * as _api from '../lib/api';
import * as _state from '../lib/state';
import * as _transaction from '../lib/transaction';

import Blur from "./Blur";

export default {
    name: 'ModalMenuDate',
    components: {
        Blur,
    },
    props: ['location_slug'],
    data() {
        return {
            active: false,
            form: {
                date: false,
            },
            errors: [],
            unavailable_dates: [],
        }
    },
    async mounted() {
        this.form.date = this.transaction.menu_date;

        if (this.config.settings.dateselection_menu == true && (['delivery', 'takeaway']).includes(this.transaction.method)) {
            await this.open()
        }
    },

    methods: {
        async open() {
            await this.getTimeslots();

            this.active = true;
        },
        close() {
            this.active = false
        },

        submit() {

            if (!this.form.date) {
                return;
            }
            if (moment(this.form.date).format('YYYY-MM-DD') != this.transaction.menu_date) {
                this.$emit('reload-menu')
            }
            _transaction.setMenuDate(moment(this.form.date).format('YYYY-MM-DD'));

            this.close();
        },

        async getTimeslots() {
            axios.defaults.headers.common['Location'] = this.location_slug

            const timeslot_data = await _api.post('timeslots', {
                date: this.form.date,
                function: 'delivery',
                area_id: null,
            })

            this.unavailable_dates = timeslot_data.attributes.unavailable_dates;
        },
    },
    computed: {

        config() {
            return _state.get('config/getConfig');
        },

        transaction() {
            return _state.get('transaction/getTransaction');
        },


    }
}
</script>
