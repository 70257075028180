<template>
    <a @click="open()" class="mr-2 cursor-pointer inline-flex justify-center items-center  px-3 py-2 btn btn-primary-outline text-sm leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
        {{ $t('modal.preorder.button.open')}}
    </a>


    <!-- modal -->
    <div class="fixed w-full z-10 inset-0 overflow-y-auto ccss--modal-preorder-activation" v-if="show">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
            <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="">
                    <div class="modal-close">
                        <a @click="close()" class="cursor-pointer text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                            <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </a>
                    </div>
                    <div class="">
                        <div class="text-center sm:text-left py-8 px-4  sm:rounded-sm sm:px-10 pb-0">
                            <h2 class="text-center text-3xl font-extrabold">
                                {{ $t('modal.preorder.title') }}
                            </h2>
                            <p class="mt-2 text-center text-sm max-w">
                                {{ $t('modal.preorder.description') }}
                            </p>
                        </div>
                    </div>

                    <div v-if="result.type == 'success'">
                        <div class="bg-green-100 overflow-hidden border border-green-400 rounded-lg divide-y divide-gray-200">
                            <div class="px-4 py-5 sm:p-6 text-center">
                                <p class="text-xl text-green-500">
                                    {{ $t('modal.preorder.success') }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div v-else-if="result.type == 'error'">
                        <div class="bg-red-100 overflow-hidden border border-red-400 rounded-lg divide-y divide-gray-200">
                            <div class="px-4 py-5 sm:p-6">
                                <h2 class="text-center text-xl font-bold text-red-500">
                                    {{ $t('modal.preorder.failed') }}
                                </h2>
                            </div>
                        </div>
                        <div class="mt-6 flex items-center justify-center">
                            <div class="text-sm">
                                <a @click="reset()" class="cursor-pointer font-medium">{{ $t('voucher.order.modal.button.tryagain')}}</a>
                            </div>
                        </div>
                    </div>
                    <div v-else class="p-4">
                        <form class="space-y-6" @submit.prevent="onSubmit">
                            <div class="">
                                <div class="my-5 px-4">
                                    <input v-model="preordernumber" placeholder="Vul hier het nummer in" id="preordernumber" name="preordernumber" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm">
                                </div>
                                <button :disabled="saving" type="submit" class="w-full inline-flex justify-center items-center px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                                    {{ $t('modal.preorder.button.submit') }}
                                    <svg v-if="saving" class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    <svg v-else class="ml-3 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd" d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L11.7071 16.7071C11.3166 17.0976 10.6834 17.0976 10.2929 16.7071C9.90237 16.3166 9.90237 15.6834 10.2929 15.2929L14.5858 11L3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H14.5858L10.2929 4.70711C9.90237 4.31658 9.90237 3.68342 10.2929 3.29289Z" clip-rule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import * as _api from '../lib/api';
import * as _state from "../lib/state";

export default {
    name: 'ModalPreorderActivation',
    props: ['location_slug', 'table_id'],
    components: {
    },
    data() {
        return {
            show: false,
            invalid: false,
            saving: false,
            preordernumber: '',
			result: {
				active: false,
				type: "",
				message: "",
				amount: 0
			},
        }
    },
    mounted() {
        axios.defaults.headers.common['Location'] = this.location_slug
    },
    methods: {

        // modal
        open() {
            this.reset();
            this.show = true;
        },

        close() {
            this.reset()
            this.show = false
        },
        reset() {
            this.preordernumber = ''
            this.result = {
				active: false,
				type: "",
				message: "",
				amount: 0
			}
        },



		async onSubmit() {

            if (!this.preordernumber) {
                return
            }

            this.saving = true

            // todo loading
            const preorder_data = await _api.post('/preorder/activate', {
                preordernumber: this.preordernumber,
                table_id: this.table_id
            })

            if (preorder_data) {
                this.result = {
                    active: true,
                    type: "success",
                    message: preorder_data.message,
                    // amount: preorder_data.amount,
                    // vouchercode: preorder_data.vouchercode,
                }
            } else {
                this.result = {
                    active: true,
                    type: "error",
                };
            }

            this.saving = false
		},

        // vouchercode input
        resetValue(i) {
            for (let x = 1; x < 6; x++) {
                if (x >= i) this.digit[i] = ''
            }
        },

        stepForward(i) {
            if (this.digit[i] && i != 6) {
                this.$refs['digit_' + (i + 1)].focus()
                this['digit_' + i] = ''

                return
            }

            let code = ''
            for (let i = 1; i < 7; i++) {
                code += this.digit[i]
            }

            if (code.length == 6) {
                this.onSubmit(code)
            }
        },

        stepBack(i) {
            if (this.digit[i - 1] && i != 0) {
                this.$refs['digit_' + (i - 1)].focus()
                this.digit[i] = ''
            }
        },
    },

    computed: {

        transaction() {
            return _state.get('transaction/getTransaction');
        },
    }
}
</script>
